import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../store/slices/Reassign/reassignSlice";
import {
  addToRecheckList,
  addToReissueList,
  getCommittedList,
  reAssignMember,
  reAssignRecheckMember,
} from "../../api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  useGetProcessListDataQuery,
  useGetUserListDataQuery,
} from "../../../../../store/queries/Orders";
import { getAgentData } from "../../../Order/api";
import { useEffect, useState } from "react";
import * as Yup from "yup";

const useReassignModel = ({ refetch, activeTab }) => {
  const dispatch = useDispatch();
  const { data: processListData } = useGetProcessListDataQuery();
  const { data: userListData } = useGetUserListDataQuery();
  const [committedList, setCommitList] = useState([]);
  const [certificateList, setCertificateList] = useState([]);

  const initialValues = {
    item_details_id: [],
    process_slug: "",
    employ_id: "",
    process_id: "",
    committed_id: "",
  };
  const validation = Yup.object({
    employ_id: Yup.string().required("*Required"),
    committed_id: Yup.string().required("*Required"),
    item_details_id: Yup.array().min(1, "*Select one").required("*Required"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: async (values) => {
      const params = {
        process_slug: values?.process_slug ?? "",
        employ_id: values?.employ_id ?? "",
        item_details_id: values?.item_details_id ?? [],
      };
      try {
        let response;
        if (activeTab === "orders") {
          response = await reAssignMember(params);
        } else {
          response = await reAssignRecheckMember(params);
        }
        if (response?.data?.status_code === 200) {
          toast.success(`Reassigned successfully`);
          setCertificateList([]);
          setCommitList([]);
          handleCancel();
          refetch();
        } else {
          toast.error(response?.message);
          setCertificateList([]);
          setCommitList([]);
          handleCancel();
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("Something went wrong");
      }
    },
  });

  const getFieldError = (fieldName) => {
    if (formik?.touched[fieldName] && formik?.errors[fieldName]) {
      return formik?.errors[fieldName];
    }
    return "";
  };

  const handleCancel = () => {
    dispatch(
      updateConfig((state) => {
        state.showReassignModal = false;
      })
    );
  };
  const processObj = processListData?.data?.filter(
    (item) => item?._id === formik?.values?.process_id
  );

  const getCommitList = (value) => {
    let param = {
      slug: value?.slug,
      process_id: value?._id,
    };
    getCommittedList(param).then((response) => {
      if (response?.data?.status_code === 200) {
        setCommitList(response?.data?.data);
      } else {
        toast.error(response?.message);
      }
    });
  };

  const committedObj = committedList?.filter(
    (item) => item?.user_id === formik?.values?.committed_id
  );
  const userObj = userListData?.data?.staff_list?.filter(
    (item) => item?.id === formik?.values?.employ_id
  );

  const handleItemChange = (value) => {
    formik.setFieldValue(
      "item_details_id",
      value?.map((v) => v?.item_id)
    );
  };


  console.log(formik?.values)
  return {
    formik,
    processData: processListData?.data,
    userList: userListData?.data?.staff_list,
    processObj,
    getFieldError,
    handleCancel,
    getCommitList,
    committedList: committedList,
    committedObj,
    certificateList,
    setCertificateList,
    userObj,
    handleItemChange,
  };
};

export default useReassignModel;
